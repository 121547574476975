import { Component,ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { Platform,ToastController,Events, MenuController,IonRouterOutlet,ActionSheetController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private toastCtrl: ToastController,
    private router: Router,
    public events: Events
  ) {
    this.presentToastEvent();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
  
  /**
   * MENSAGENS NA SOLUÇÃO
   */

  presentToastEvent() {
    this.events.subscribe('application:Toast', (mensagem, tipo) => {
      this.presentToast(mensagem, tipo);
    });
  }

  async presentToast(mensagem, tipo) {
    var cortoast="primary";
    // Warning
    if (tipo==1){
      var cortoast="secondary";
    }
    else if (tipo==2){
      var cortoast="danger";
    }

    const toast = await this.toastCtrl.create({
      message: mensagem,
      duration: 7000,
      position: 'top',
      showCloseButton:true,
      closeButtonText:"Fechar",
      color:cortoast
    });
    toast.present();
  }
}
